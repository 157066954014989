import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faWhatsapp, faLinkedin, faFacebook, faInstagram } from "@fortawesome/free-brands-svg-icons";

export default function Example() {
  return (
    
    <div style={{ overflow: "hidden !important" }} >
      <main>
        <div className="mx-auto max-w-7xl w-full pt-16 pb-20 text-center">
          <div className="px-4 px-8">
            <h1 className="text-4xl tracking-tight font-extrabold text-gray-900 text-5xl">
              <span className="block">MOEZ GRAMI</span> <span className="text-2xl block text-indigo-600">SOFTWARE ENGINEER</span>
            </h1>
            <p className="mt-2 max-w-xl mx-auto text-gray-800 text-lg">
            I am a computer science engineer with a passion for competitive programming, always look forward to making the best during my tasks and my projects no matter how challenging the situations.
            </p>
            <div className="mt-2 flex justify-center">
              {/* View Details and Contact Buttons */}
              <div className="mt-3 rounded-md shadow mt-0 ml-3">
                <a
                  href="cv.html"
                  className="w-full flex items-center justify-center px-8 py-3 border border-transparent text-base font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700">
                  Contact us
                </a>
              </div>
              {/*
              <div className="mt-3 rounded-md shadow mt-0 ml-3">
                <a
                  href="tel:+21624582842"
                  className="w-full flex items-center justify-center px-8 py-3 border border-transparent text-base font-medium rounded-md text-indigo-600 bg-white hover:bg-gray-50">
                  View details
                </a>
              </div>
              */}
            </div>
            <div className="mt-5 flex justify-center">
              {/* LinkedIn Icon */}
              <a href="https://www.linkedin.com/in/moez-grami-98616812b" target="_blank" rel="noopener noreferrer" className="text-blue-600 mx-2 hover:text-blue-800">
                <FontAwesomeIcon icon={faLinkedin} size="2x" />
              </a>
              {/* Facebook Icon */}
              <a href="https://www.facebook.com/moez1997/" target="_blank" rel="noopener noreferrer" className="text-blue-600 hover:text-blue-800 mx-2">
                <FontAwesomeIcon icon={faFacebook} size="2x" />
              </a>
              {/* Instagram Icon */}
              <a href="https://www.instagram.com/grami_moez/?hl=fr" target="_blank" rel="noopener noreferrer" className="text-pink-600 hover:text-pink-800 mx-2">
                <FontAwesomeIcon icon={faInstagram} size="2x" />
              </a>
               {/* WhatsApp Icon */}
               <a href="https://wa.me/+21624582842" target="_blank" rel="noopener noreferrer" className="text-green-600 hover:text-green-800 mx-2">
                <FontAwesomeIcon icon={faWhatsapp} size="2x" />
              </a>
            </div>
            
          </div>
        </div>
        {/*
        <div className="relative w-full h-64 h-72">
          <img
            src="/img/testimonial-2.jpg"
            alt=""
          />
        </div>*/}
      </main>
    </div>
  )
}
